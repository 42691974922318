import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Users from '../views/UsersView.vue'
import Aco from '../views/AcoView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:name',
    name: 'home_fix',
    component: HomeView
  },
  {
    path: '/getlicense',
    name: 'getlicense',

    component: Users
  },
  {
    path: '/getlicense/:id',
    name: 'Reg',

    component: Users
  },
  {
    path: '/acolinux/getlicense',
    name: 'homeacolinux',

    component: HomeView
  },
  {
    path: '/acolinux/:name',
    name: 'homeacolinux_fix',

    component: HomeView
  },
  {
    path: '/acolinux/getlicense/:id',
    name: 'AcoReg',

    component: Aco 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
